var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.environment == "staging"
    ? _c(
        "div",
        { staticClass: "warning" },
        [
          _c(
            "gov-width-container",
            [
              _c(
                "gov-warning-text",
                { attrs: { title: "IMPORTANT" } },
                [
                  _vm._v(
                    "\n      Please DO NOT make any changes to this site. This is a TEST environment\n      used for demo purposes only. Any changes made here will not be reflected\n      on the LIVE site viewed by the public.\n      "
                  ),
                  _c("gov-link", { attrs: { href: _vm.adminUrl } }, [
                    _vm._v("Click HERE ")
                  ]),
                  _vm._v("\n      to access the LIVE environment.\n    ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }